import React from "react"
import Image from "../../elementsWeb/Image"
import Text from "../../elementsWeb/Text"
import Heading from "../../elementsWeb/Heading"
import Container from "../../elementsWeb/UI/Container"
import HubspotForm from "react-hubspot-form"
import Box from "../../elementsWeb/Box"
import MsDynamicsForm from "../../componentsWeb/MsDynamicsForm"
import ContactUsSectionWrapper from "./contactUsSection.style"
import TopLeftHex from "../../assetsWeb/images/top-left-hexagons.png"
import BottomRightHex from "../../assetsWeb/images/bottom-right-hexagons.png"

const ContactUsSection = ({ secTitle, secText, secDes }) => {
  return (
    <ContactUsSectionWrapper id="testimonial_section">
      <Container>
        <Image
          src={TopLeftHex}
          alt="decoration"
          className="decor-hex top-left"
        />
        <Image
          src={BottomRightHex}
          alt="decoration"
          className="decor-hex bottom-right"
        />
        <Text {...secText} content={"GET IN TOUCH"} />
        <Heading
          {...secTitle}
          as="h2"
          content={"We would love to hear from you."}
          a={"contact"}
        />
        <Text
          {...secDes}
          content={
            "Leave us a message or just contact information and we will reach out to you."
          }
        />
        <Box className="form">
          {/* <HubspotForm
            portalId="4315346"
            formId="5d48954f-8903-4e95-ad7f-fc154373758a"
            loading={<div>Loading...</div>}
          /> */}
          <MsDynamicsForm
            formBlockId="f6ee6dd5-40fb-ef11-bae2-000d3a69d993"
            elementId="d1Jyf-DnY5jMkzjYNCknrgANQFGshDO2Zg43TwDjmz60"
          />
        </Box>
        <Text
          {...secDes}
          content={
            "NOTE: All questions before sign up will be answered through email at hello@3dsecure.io"
          }
        />
      </Container>
    </ContactUsSectionWrapper>
  )
}

// ContactUsSection default style
ContactUsSection.defaultProps = {
  secTitle: {
    fontSize: ["20px", "24px", "36px", "36px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    ml: "auto",
    mr: "auto",
    lineHeight: "1.12",
    mb: ["20px", "25px", "25px", "40px"],
    textAlign: "center",
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "primary",
    mb: "12px",
  },
  secDes: {
    fontSize: "16px",
    color: "#5d646d",
    lineHeight: "1.875",
    mt: "5px",
    ml: "auto",
    mr: "auto",
    maxWidth: "900px",
    textAlign: "center",
  },
}

export default ContactUsSection
