import React, { useEffect } from "react"

const MsDynamicsForm = ({ formBlockId, elementId }) => {
  useEffect(() => {
    // Load the form loader script
    const formScript = document.createElement("script")
    formScript.src =
      "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007"
    formScript.async = true
    document.body.appendChild(formScript)

    // Load the tracking script
    const trackingScript = document.createElement("script")
    trackingScript.src =
      "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"
    trackingScript.async = true
    document.body.appendChild(trackingScript)

    return () => {
      // Clean up the scripts when the component is unmounted
      if (document.body.contains(formScript)) {
        document.body.removeChild(formScript)
      }
      if (document.body.contains(trackingScript)) {
        document.body.removeChild(trackingScript)
      }
    }
  }, [])

  return (
    <div class="form_ms-dynamics">
      <div data-form-block-id={formBlockId}></div>
      <div id={elementId}></div>
      <div
        className="d365-mkt-config"
        style={{ display: "none" }}
        data-website-id="1Jyf-DnY5jMkzjYNCknrgANQFGshDO2Zg43TwDjmz60"
        data-hostname="11d8102b4001415d9e9c3eb3a9eb6a2f.svc.dynamics.com"
      ></div>
    </div>
  )
}

export default MsDynamicsForm
